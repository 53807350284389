import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import App from "./App"

const overrides = {
  sizes: {
    container: {
      '2xl': '1440px'
    }
  },
  colors: {
    primary: {
      50: '#dbffff',
      100: '#b0fcfa',
      200: '#83f8f5',
      300: '#56f6f1',
      400: '#2ff3ed',
      500: '#1cdad4',
      600: '#09a9a5',
      700: '#007976',
      800: '#004a48',
      900: '#001a1a',
    }
  },
}

const theme = extendTheme(overrides)

export default function ChakraApp() {
  return (
    <ChakraProvider theme={theme} cssVarsRoot="body">
      <App />
    </ChakraProvider>
  )
}