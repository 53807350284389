import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingScreen from './components/loading-screen';
import './index.css'

const Layout = lazy(() => import('./layouts'))
const Home = lazy(() => import('./pages/Home'))

function App() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout children={ <Home/> } /> } />
          <Route path='/test' element={<LoadingScreen /> } />
        </Routes>
      </BrowserRouter>
    </Suspense>

  );
}

export default App;
